// https://github.com/NewOldMax/react-material-ui-form-validator
import React, { useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import { FormControl, InputLabel, MenuItem } from '@mui/material';

import Checkbox from "@mui/material/Checkbox";
import NativeSelect from '@mui/material/NativeSelect';

import Done from '@mui/icons-material/Done'
import moment from 'moment';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


const AlixonRes = ({res='', is_test, apihost, ...props}) => {
           
  require('moment/locale/de');
  moment.locale('de');

  const getRegFelder = () => {
    return [{id:"resanrede",l:"Anrede"},{id:"resvorname",l:"Vorname"},{id:"resname",l:"Name"},{id:"resemail",l:"E-Mail"},{id:"resstrasse",l:"Strasse, Nr"},
            {id:"resplz",l:"PLZ"},{id:"resort",l:"Ort"},{id:"restel",l:"Telefon"},{id:"resuser2",l:"Benutzername"},
            {id:"respasswort2",l:"Passwort", t:"password"},{id:"respasswort3",l:"Passwort wiederholen", t:"password"}];

  }           

  const validate = () => {
    var val = true;
    var err = {};
    switch (adjStep) {
      case 0:
      default:            
        if (data.anlass.length===0) {val=false; err.anlass = true;}
        if (data.start < moment()) {val=false; err.start = true;}
        break;
      case 1:
        var objcnt = 0;
        data.objekte.map((o)=>{if (o.selected) objcnt++; return 0})
        if (objcnt===0) {val=false; err.objekte = true;}
        break;
      case 2:
          data.felder.map((f)=>{
            if (!f.allowBlank && (!data[f.id] || data[f.id].length===0)) {val=false; err[f.id] = data[f.id]!==undefined;}
            return 0
          });
        break;
      case 3:
        if (reg) {
          getRegFelder().map((f)=>{
            if (!data[f.id] || data[f.id].length===0) {val=false; err[f.id] = data[f.id]!==undefined;}
            return 0
          });
          if (!data.resuser2 || !data.resuser2.match(/^[a-z0-9]{6,}$/)) {
            val = false;
            err.resuser2 = 1;
          }
          if (data.userok) {
            val = false;
            err.resuser2 = true;
          }
          if (data.respasswort2!==data.respasswort3) {
            val = false;
            err.respasswort3 = true;
          }
        }
        else {
          if (!data.userok) {val=false; }
          if (!data.passwdok) {val=false;}
        }
        break;
    }
    setError(err);
    setValid(val);
    return val;
  }
                             
  var parts = window.location.search.substr(1).split("&");
  var get = {};
  for (var i = 0; i < parts.length; i++) {
    var temp = parts[i].split("=");
    get[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
  }
  
  const [activeStep, setActiveStep] = useState(0);
  const [adjStep, setAdjStep] = useState(0);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState({});
  const [reg, setReg] = useState(false);
  const [data, setData] = useState({
      notInit: true,
      kunde: window.location.hash.substring(1),
      userok: false,
      passwdok: false,
      anlass: "",
      start: get.date?moment(get.date+'+00:00'):moment().add(1,'h').minutes(0).seconds(0),
      dauer:"1",
      vor:0,
      nach:0,
      objekte: [],
      preselect:get.obj?get.obj.split('!'):[],
      enable:get.en?get.en.split('!'):[],
      felder: [],
    }
  );
  var timer = null;
  
  useEffect(() => {
    validate();
  }, [data]);
  
  const updateData = () => {
    var fdata = new FormData();
    var d = {...data};
    var url = (is_test?"http://test":"https://produktiv")+".alixon-web.ch/resapi.php"
    if(res !== ''){
      url = "https://"+apihost+"resReservation/"+res+"/1"
    }
    d.start = d.start.format();
    if (!d.action) {
      switch(adjStep) {
        case 0: d.action = d.notInit?'Init':'GetObjects'; break;
        case 1: d.action = 'GetObjects'; break;
        case 4: d.action = 'Send'; break;
        default: validate(); return;
      }
    }
    fdata.append( "data", JSON.stringify( d ) );
    fetch(url,
    {
        method: "POST",
        body: fdata
    })
    .then((res) => res.json())
    .then((rdata) => {
      rdata.start = moment(rdata.start);
      rdata.action = '';
      setData(rdata);
    })   
  };

  useEffect(() => {
    updateData();
  }, [activeStep]);
  
  useEffect(() => {
    if (data.action) {
      updateData();
    }
  }, [data]);
  
  const handleNext = () => {
    if (!validate()) {
      return
    }
    setActiveStep(activeStep + 1);
    setAdjStep(adjStep + 1 + (activeStep===1 && data.felder.length===0?1:0));
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setAdjStep(adjStep - 1 - (adjStep===3 && data.felder.length===0?1:0));
  };

  const handleSwitchReg = () => {
    setReg(!reg);
  };

  const updateField = (e) => {
//    console.log([e.target.name, e.target.value])
    setData({...data, [e.target.name]:e.target.value});
  }
  
  const handleStartChange = (date, ev) => {
//    console.log(['start', date.format('DoMM.YY HH:mm'), ev])
    setData({...data, start: date});
  }
  const handleEndeChange = (date) => {  
    const diff = date.diff(data.start, 'm') / 60;
//    console.log([date, diff]);
    if (diff > 0) {
      setData({...data, dauer: diff.toFixed(2)});
    }
  }  
  
  const handleDauerChange = (e) => {
    clearTimeout(timer)
    setData({...data, dauer: e.target.value});
  }
  const handleSelectObject = (event, index) => {
    const obj = data.objekte.slice();
    if (obj[index].frei) {
      const d = {...data, objekte: obj};
      obj[index].selected = ! obj[index].selected;
      if (obj[index].selected) {
        if (parseInt(obj[index].minvor)>data.vor) {
          d.vor = parseInt(obj[index].minvor);
        }
        if (parseInt(obj[index].minnach)>data.nach) {
          d.nach = parseInt(obj[index].minnach);
        }
      }
      setData(d);
    }
  };
  
  const handleSelectVariante = (event, index) => {
    const obj = data.objekte.slice();
    obj[index].variante = event.target.value;
    const d = {...data, objekte: obj};
    setData(d);
  };
  
  const handleChangeUsername = (e) => {
    const d = {...data, [e.target.name]:e.target.value, action:'CheckUser'};
    setData(d);
  }
  const handleChangeUsername2 = (e) => {
    const d = {...data, [e.target.name]:e.target.value, action:'CheckUser2'};
    setData(d);
  }
  const handleChangePassword = (e) => {
    const d = {...data, [e.target.name]:e.target.value, action:'CheckPassword'};
    setData(d);
  }

  const submit = (values, pristineValues) => {
    // get all values and pristineValues on form submission
  }

  const steps = data.felder.length
    ? ['Anlass, Datum', 'Objekte wählen', 'Details', 'Login', 'Fertig']
    : ['Anlass, Datum', 'Objekte wählen', 'Login', 'Fertig'];
  
  var objekte = [];
  var lastparent = '';
  for (i=0; i<data.objekte.length; i++) {
    const obj = data.objekte[i];      
    if (obj.leaf==="1" || obj.leaf===1) {
      if (obj.selected) {
        objekte.push((lastparent?lastparent+'-':'')+obj.name);
      }
    } 
    else { 
      lastparent = obj.name
    }
  }
  objekte = objekte.join(', ');
  
  const ende = moment(data.start);
  ende.add(data.dauer, 'h');
  var lastistitle = false;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="anlass"
                    name="anlass"
                    label="Bezeichnung Anlass"
                    value={data.anlass}
                    error={error.anlass}
                    helperText={error.anlass?"Bitte Bezeichnung des Anlasses eingeben.":""}
                    onChange={updateField}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <MobileDateTimePicker 
                    label="Start"
                    ampm={false}
                    value={data.start}
                    onChange={handleStartChange}
                    error={error.start}
                    shouldDisableTime={(timeValue, clockType) => { return clockType === "hours" && timeValue < data.firstonline; }}
                    helperText={error.start?"Start muss in der Zukunft liegen.":""}
                    renderInput={(props) => <TextField {...props} style={{marginTop:'16px'}} fullWidth />}
                    disableMaskedInput={true}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <TextField
                    id="dauer"
                    name="dauer"
                    label="Dauer (Stunden)"
                    value={data.dauer}
                    onChange={handleDauerChange}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MobileDateTimePicker 
                    renderInput={(props) => <TextField {...props} style={{marginTop:'16px'}} fullWidth />}
                    id="ende"
                    name="ende"
                    label="Ende"
                    ampm={false}
                    minDateTime={data.start}
                    value={ende}
                    onChange={handleEndeChange}
                    error={error.ende}
                    helperText={error.ende?"Ende muss nach Start sein.":""}
                    margin="normal"
                    disableMaskedInput={true}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
          {data.vornach &&    
              <>
                <Grid item  xs={12} sm={3}>
                <TextField
                  id="von"
                  name="von"
                  label="Übernahme"
                  value={moment(data.start).subtract(data.vor, 'm').format('HH:mm')}
                  margin="normal"
                  disabled={true}
                  fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={3}>
                <TextField
                  id="vor"
                  name="vor"
                  label="Vorbereitung (Minuten)"
                  InputLabelProps={{ shrink: true }}
                  value={data.vor}
                  onChange={updateField}
                  margin="normal"
                  fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={3}>
                <TextField
                  id="nach"
                  name="nach"
                  label="Aufräumen (Minuten)"
                  InputLabelProps={{ shrink: true }}
                  value={data.nach}
                  onChange={updateField}
                  margin="normal"
                  fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={3}>
                <TextField
                  id="bis"
                  name="bis"
                  label="Abgabe"
                  value={moment(ende).add(data.nach, 'm').format('HH:mm')}
                  margin="normal"
                  disabled={true}
                  fullWidth
                />
                </Grid>
              </>
          }
              </Grid>
            </LocalizationProvider>
        );
      case 1:
        return (
          <div>
              {data.objekte.map((obj, index) => {
                if (obj.leaf==="1" || obj.leaf===1) {
                  const topline = lastistitle;
                  lastistitle = false;
                  return (
                  <div className={"objrow lvl_"+obj.lvl+((obj.lvl==="2" || obj.lvl===2 || topline)?"":" linetop")}>
                    <div onClick={event => handleSelectObject(event, index)} >
                      {obj.frei? (
                          <Checkbox checked={obj.selected} />
                      ):(
                      <p>belegt</p>
                      )}
                    </div>
                  <div style={{paddingLeft:(obj.lvl*2)+'em'}}>
                    <p>{obj.name}</p>
                  </div>
                  <div>
                {parseInt(obj.pflichtvariante)>0 && obj.selected &&
                    <NativeSelect value={obj.variante} onChange={event => handleSelectVariante(event, index)}>
                  {obj.optionen.split(';').map(opt=>(
                      <option>{opt}</option>
                  ))}
                    </NativeSelect>
                }                  
                  </div>
                  </div>
                )
                } else { 
                  lastistitle = true;
                  return (
                  <div className="objrow" key={obj.id}>
                    <div />
                    <div className={"head_"+obj.lvl}>{obj.name}</div>
                    <div />
                  </div>
                )}
              })}
            </div>
        );
      case 2:
        return (
            <div>
              <LocalizationProvider  dateAdapter={AdapterMoment} >
                {data.felder.map((feld, index) => {
                  if (feld.type=='select') {
                    return (
                      <FormControl id={'lb'+feld.id} fullWidth style={{marginTop:'16px'}}>
                        <InputLabel>{feld.label}</InputLabel>
                        <Select
                          id={feld.id}
                          key={feld.id}
                          labelId={'lb'+feld.id}
                          label={feld.label}
                          name={feld.id}
                          defaultValue={feld.default?feld.default:feld.options[0].text}
                          onChange={updateField}
                          margin="normal"
                        >
              {feld.options.map((el)=>
                          <MenuItem value={el.text}>{el.text}</MenuItem>
              )}
                        </Select>
                      </FormControl>
                    )
                  }
                  else {
                    return (
                      <FormControl id={'lb'+feld.id} fullWidth>
                        <InputLabel>{feld.label}</InputLabel>
                        <TextField
                          required={!feld.allowBlank}
                          id={feld.id}
                          key={feld.id}
                          labelId={'lb'+feld.id}
                          name={feld.id}
                          defaultValue=""
                          onChange={updateField}
                          error={error[feld.id]}
                          helperText={error.username?"Bitte "+feld.label+" eingeben.":""}
                          margin="normal"
                        />
                      </FormControl>
                    )  
                  }
                })}
              </LocalizationProvider >
            </div>
        );
      case 3:
        return (
            <div>
              <LocalizationProvider  dateAdapter={AdapterMoment}>
                {reg ? (
                  <div>
                  {getRegFelder().map((feld, index) => {
                    return (
                        <TextField
                          required
                          id={feld.id}
                          name={feld.id}
                          label={feld.l}
                          type={feld.t}
                          defaultValue=""
                          onChange={feld.id==='resuser2'?handleChangeUsername2:updateField}
                          error={!!error[feld.id]}
                          helperText={feld.id==='resuser2' && error.resuser2?(error.resuser2===1?"Mindesten 6 Zeichen, nur Kleinbuchstaben oder Zahlen":"Benutzername bereits vergeben."):""}
                          margin="normal"
                          fullWidth
                        />
                      )
                  })}
                  <Button
                    onClick={handleSwitchReg}
                    variant="outlined" 
                    style={{float:"right"}}
                  >
                    Bereits registriert
                  </Button>
                  </div>
                ) : (
                  <div>
                  <TextField
                    required
                    id="username"
                    name="username"
                    label="Benutzername"
                    defaultValue=""
                    onChange={handleChangeUsername}
                    error={error.username}
                    helperText={error.username?"Bitte Benutzername eingeben.":""}
                    margin="normal"
                    InputProps={{
                      endAdornment: data.userok?(<InputAdornment position="end"><Done style={{color:"#00BB00"}} /></InputAdornment>):null
                    }}
                  />
                  <div>
                  <TextField
                    required
                    id="password"
                    name="password"
                    label="Passwort"
                    defaultValue=""
                    onChange={handleChangePassword}
                    error={error.password}
                    helperText={error.password?"Bitte Passwort eingeben.":""}
                    type="password"
                    margin="normal"
                    InputProps={{
                      endAdornment: data.passwdok?(<InputAdornment position="end"><Done style={{color:"#00BB00"}} /></InputAdornment>):null
                    }}
                  />
                  </div>
                  <Button
                    onClick={handleSwitchReg}
                    variant="outlined" 
                  >
                    Neu registrieren
                  </Button>
                  </div>
                )}
              </LocalizationProvider>
            </div>
        )
      case 4:
        return data.success ? (
          data.prov 
            ? <div>
                <h2>Reservation provisorisch eingetragen</h2>
                <p>Sie werden per E-Mail weiter informiert</p>
              </div>
            :  <h2>Reservation definitiv eingetragen</h2>
          ) : (
          data.done
            ? <div>
                <h2>Ein Fehler ist aufgetreten.</h2>
                <p>Bitte versuchen Sie es später nochmals oder fragen Sie per E-Mail an.</p>
              </div>
            :  <div></div>
          );
        
      default:
        return 'Unknown step';
    }
  }
  
  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    {activeStep>0 &&
      <Container maxWidth="lg">
        <Paper>
        <Card>
        <CardContent>
          <b>Objekte:</b> {objekte}<br/>
          <b>Übernahme:</b> {moment(data.start).format('DoMM.YY HH:mm')} 
          <b> Abgabe:</b> {moment(ende).format('DoMM.YY HH:mm')}
      {(data.vor > 0 || data.nach > 0) && 
          <div>
            <b>Reservation:</b> {moment(data.start).subtract(data.vor, 'm').format('DoMM.YY HH:mm')} bis {moment(ende).add(data.nach, 'm').format('DoMM.YY HH:mm')}
          </div>
      }    
        </CardContent>
        </Card>
        </Paper>
      </Container>
    }
      <form onSubmit={submit}>
          <div>
            <Container maxWidth="lg">
              {getStepContent(adjStep)}
              {adjStep<4 ? (                
              <div>
                <Button
                  variant="outlined" 
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Zurück
                </Button>
                <Button
                  variant="contained"
                  disabled={! valid}
                  color="primary"
                  onClick={handleNext}
                >
                  {adjStep === 3 ? "Anfrage senden":"Weiter"}
                </Button>
              </div>
              ):null}
            </Container>
          </div>
      </form>
    </div>
  );


}

export default AlixonRes;
