// https://github.com/NewOldMax/react-material-ui-form-validator
import React, { useEffect, useState} from 'react';
import { Stack, Switch, Autocomplete, Grid, Paper, Container, Card, CardContent, TextField, FormControl, FormLabel, FormControlLabel, FormHelperText, RadioGroup, Radio, Button, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const ResStructureElement = ({ f }) => {
  if(f.ueberschrift){
    return (
      <>
        <Box width="100%"/>
        <Grid item xs={12} sm={12} lg={12} key={"grid"+f.id_freiesfeld}>
          <h3>
            {f.ueberschrift}
          </h3>
        </Grid>
      </>
    )
  }
  if(f.abstandvor){
    return <Box width="100%" height="1.5em" />
  }
}

const AlixonEvent = ({ev='', is_test, apihost, ...props}) => {
  const [eventKey, setEventKey] = useState(ev);
  const [event, setEvent] = useState({});
  const [msg, setMsg] = useState("");
           
  require('moment/locale/de');
  moment.locale('de');
  
  const getChoices = (opts) => {
    if (typeof opts === 'object') return opts;
    var options = []
    if(opts){
      opts.split(';').forEach((c)=>{
        options.push({id:c, text:c});
      })
    }
    return options
  }
  
  const getLabel = (option) => {
    if (typeof option === 'object') return option.text;
    if (!option) return '';
    return option;
  }
  
  const getOptDisabled = (option) => {
    console.log(option);
    if(option.disabled)return true
    return false
  }
  
  const RadioList = ({source, tip, onChange, options, label}) => {
    const value = event&&event[source]?event[source]:''
    return (
      <FormControl>
        <FormLabel id={"rbgr"+source}>{label}</FormLabel>
        <RadioGroup
          aria-labelledby={"rbgr"+source}
          name={"crbg"+source}
          value={value}
          onChange={onChange}
        >
        { options && options.map((f)=>(
          <FormControlLabel value={f.id} control={<Radio />} label={f.text} disabled={f.disabled} />
        ))}
        </RadioGroup>
        <FormHelperText>{tip}</FormHelperText>
      </FormControl>
    )
  }

  const FlexInput = ({source, typ, funktion, disabled, tip, ...rest}) => {
    /*
      0:Textfeld
      1:Datum
      2:Auswahl
      2-7:Auswahl und Eingabe
      3:Mehrzeilig,
      4:JaNeinFeld
      5:Zwischentitel
      6:Radio Buttons
    */
    // console.log(rest)
    const value = event&&event[source]?event[source]:''
    if (typ===0) {
      return (<TextField {...rest} helperText={tip} defaultValue={value} onBlur={(ev)=>updateField(ev)} disabled={disabled} />);
    }
    else if (typ===1) {
      return (<DatePicker {...rest} renderInput={(props) => <TextField {...props} style={{marginTop:'16px'}} fullWidth />} value={moment(value)} defaultValue={moment(value)} onChange={(ev)=>{updateDate(ev, source)}} disabled={disabled} />);
    }
    else if (typ===2) {
      if (funktion===7)
        return (<Autocomplete {...rest} value={value} 
          onChange={(ev, val)=>updateSelect(val, source)} 
          blurOnSelect freeSolo={true} disabled={disabled} 
          renderInput={(params) => <TextField id={source} {...params} label={rest.label} helperText={tip} />} 
          getOptionLabel={getLabel}
          getOptionDisabled={getOptDisabled}
          />);
      else 
        return (<Autocomplete {...rest} value={value} 
          onChange={(ev, val)=>updateSelect(val, source)} 
          blurOnSelect disabled={disabled} 
          renderInput={(params) => <TextField id={source} {...params} label={rest.label} helperText={tip} />} 
          getOptionLabel={getLabel}
          getOptionDisabled={getOptDisabled}
          />);
    }
    else if (typ===3) {
      return (<TextField {...rest} helperText={tip} defaultValue={value} onBlur={(ev)=>updateField(ev)} multiline disabled={disabled} />);
    }
    else if (typ===4) {
      return (<FormControlLabel {...rest} control={<Switch checked={event[source]===true} onChange={updateBool} inputProps={{ 'aria-label': 'controlled' }} />} />);
    }
    else if (typ===5) {
      return (<h3>{rest.label}</h3>);
    }
    else if (typ===6) {
      return (<RadioList {...rest} value={value} source={source} onChange={(ev, val)=>updateSelect({id:val}, source)} disabled={disabled} 
      renderInput={(params) => <TextField id={source} {...params} label={rest.label} helperText={tip} />} 
      />);
    }
  }
  
  const updateData = () => {
    var fdata = new FormData();
    const pflichtFelder = {Text:true, Mehrzeilig: true, Anzahl: true, Termin: true}
    var errFelder = []
    for(let key in event.fields){
      console.log(event.fields[key])
      if(event.fields[key].pflicht === 1 && event.fields[key].erfassen === 1 && (event.fields[key].typ%2 === 1 || event.fields[key].typ === 0)){
        if(!event['frei_'+event.fields[key].id_freiesfeld]){
          errFelder.push(event.fields[key].feldname)
        }
      }
    }
    for(let key in event.res){
      console.log(event.res[key])
      if(event.res[key].pflicht === 1 && pflichtFelder[event.res[key].typ]){
        if(event['rval_'+event.res[key].ressource_id] == ""){
          errFelder.push(event.res[key].feldbez)
        }
      }
    }
    console.log(errFelder)
    if(errFelder.length > 0){
      setMsg("Folgende Felder können nicht leer sein: "+errFelder.join(", "))
      return
    }
    for(let key in event) {
      if(key.startsWith('rval_')){
        console.log([key,event[key]])
        fdata.append( key, event[key] );
      }
      if(key.startsWith('frei_')){
        console.log([key,event[key]])
        fdata.append( key, event[key] );
      }
    }
    console.log(window.location.hostname)
    fetch("https://"+apihost+"eveRegister/"+eventKey+"/1",
    {
        method: "POST",
        body: fdata
    })
    .then((res) => res.json())
    .then((rdata) => {
      if(rdata.error){
        setMsg(rdata.error);
      }
      if(rdata.newKey){
        setEventKey(rdata.newKey);
        window.location = '/?e='+rdata.newKey;
      }
      else if(rdata.ok){
        window.location = '/?e='+eventKey;
      }
    })   
  };

  useEffect(() => {
    fetch("https://"+apihost+"eveRegister/"+eventKey+"/1",
    {
        method: "GET",
    })
    .then((res) => res.json())
    .then((rdata) => {
      //console.log(rdata);
      setEvent(rdata)
    })   
  }, []);
  const error = {}

  const updateBool = (e) => {
    console.log(e)
    setEvent({...event, [e.target.name]:(e.target.checked?true:false)});
  }

  const updateDate = (e, src) => {
    setEvent({...event, [src]:(e.format('YYYY-MM-DD'))});
  }

  const updateSelect = (val, src) => {
    console.log([src, val])
    setEvent({...event, [src]:val.id});
  }
  
  const updateField = (e) => {
    setEvent({...event, [e.target.name]:e.target.value});
  }
  //console.log(event)

  const ResFelder = () => {
    if(event && event['aid']>0)return(
      <div style={{ paddingLeft:1+'em', paddingTop:'1em' }}>
        <h2>Sie sind Angemeldet</h2>
      </div>
    )
    return (
      <>
        <div style={{ paddingLeft:1+'em', paddingTop:'1em' }}>
          <h2>Eventdaten:</h2>
        </div>
        <Grid container spacing={2}>
        { event && event.res && event.res.map((f)=>(
          <>
            <ResStructureElement f={f} />
            <Grid item xs={12} sm={f.ganzezeile===1?12:6} lg={f.ganzezeile===1?12:3} key={"res"+f.ressource_id}>
              <Stack>
                <FlexInput 
                  name={"rval_"+f.ressource_id} 
                  label={f.feldbez} 
                  key={f.ressource_id} 
                  source={"rval_"+f.ressource_id} 
                  typ={ETMap[f.typ]} funktion={0} 
                  options={getChoices(f.optionen)} 
                  disabled={f.disabled} 
                  required={f.pflicht===1} 
                  fullwidth={f.ganzezeile===1?1:undefined} 
                />
              </Stack>
            </Grid>
          </>
        ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
            <Button variant="contained" color="primary" onClick={updateData} sx={{ mt:'1em'}}>
              Anmeldung abschicken
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  const ETMap = {Text:0, Mehrzeilig: 3, Kontrollkästchen: 4, Auswahl: 2, 'Ausw. Tab.':6, Anzahl: 0, Termin: 1};
  return (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Dialog onClose={()=>{setMsg("")}} open={msg!=""} maxWidth="md" >
      <Close onClick={()=>{setMsg("")}} sx={{ position:'absolute', right:'8px', top:'8px', cursor:'pointer'}} />
      <DialogTitle>Achtung</DialogTitle>
      <DialogContent>
        {msg}
      </DialogContent>
    </Dialog>
    <Container maxWidth="lg">
      <Paper>
        <Card>
          <CardContent>
            <div style={{paddingLeft:1+'em'}} key="EvHead">
              <h1>Anmeldung für den Event: {event.eventname}</h1>
              <p>Datum: {moment(event.datum).format('DD.MM.YYYY')}</p>
            </div>
            <div style={{ paddingLeft:1+'em', paddingTop:'1em' }} key="persDatHead" >
              <h2>Personendaten:</h2>
            </div>
            <Grid container spacing={2}>
            { event && event.fields && event.fields.map((f)=>(
              <Grid item xs={12} sm={6} lg={3} key={"grid"+f.id_freiesfeld}>
                <Stack>
                  <FlexInput 
                    name={"frei_"+f.id_freiesfeld} 
                    label={f.feldname} 
                    key={f.id_freiesfeld} 
                    source={"frei_"+f.id_freiesfeld} 
                    typ={f.typ} 
                    funktion={f.funktion} 
                    options={getChoices(f.optionen)} 
                    disabled={(f.erfassen===0&&event&&event["frei_"+f.id_freiesfeld])&&event["pid"]>0} 
                    tip={f.tip} 
                    required={f.pflicht===1} 
                  />
                </Stack>
              </Grid>
            ))}
            </Grid>
            <ResFelder />
          </CardContent>
        </Card>
      </Paper>
    </Container>
  </LocalizationProvider>
  )
}

export default AlixonEvent;
