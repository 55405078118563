import React, { useEffect, useState} from 'react';
import AlixonRes from './AlixonRes';
import AlixonEvent from './AlixonEvent';

const App = () => {
  console.log(window.location)
                             
  var parts = window.location.search.substr(1).split("&");
  var get = {};
  var ev = ''
  var res = ''
  var comp = 'res';
  for (var i = 0; i < parts.length; i++) {
    var temp = parts[i].split("=");
    get[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
  }
  let apihost = window.location.hostname + '/api/';
  if (window.location.port === "3004"){
    apihost = 'aw23b.alixon.ch/api/';
  }

  const is_test = window.location.hostname !== 'res.alixon-web.ch' && window.location.hostname !== 'event.alixon-web.ch';
  if(window.location.hostname === 'event.alixon-web.ch' || window.location.hostname === 'eventtest.alixon-web.ch'){
    comp = 'event';
  }
  if(typeof get.e !== 'undefined'){
    ev = get.e
    comp = 'event'
  }
  if(typeof get.r !== 'undefined'){
    res = get.r
    comp = 'res'
  }

  if(comp === 'res'){
    return (<AlixonRes res={res} is_test={is_test} apihost={apihost} />)
  }
  else {
    return <AlixonEvent ev={ev} is_test={is_test} apihost={apihost} />
  }
}

export default App;
